import { ClientSideSuspense } from '@liveblocks/react'
import { ErrorBoundary } from '@sentry/react'
import { useContext } from 'react'
import AdminCommentContext from 'src/components/Comments/AdminCommentContext'
import CommentContext from 'src/components/Comments/CommentContext'
import { ThreadNotificationData } from 'src/components/Notifications/ThreadNotification'
import ThreadNotificationToastContent from 'src/components/Notifications/ThreadNotificationToastContent'

const ThreadNotificationToast = ({
  notification,
  onClose,
  isAdmin,
}: {
  notification: ThreadNotificationData
  onClose: () => void
  isAdmin: boolean
}) => {
  const { CommentsProvider, hasLoaded: regularCommentContextLoaded } =
    useContext(CommentContext)
  const { AdminCommentsProvider, hasLoaded: adminCommentContextLoaded } =
    useContext(AdminCommentContext)

  if (isAdmin && !adminCommentContextLoaded) return null
  if (!isAdmin && !regularCommentContextLoaded) return null

  const Provider = isAdmin ? AdminCommentsProvider : CommentsProvider

  return (
    <Provider>
      <ErrorBoundary fallback={<></>}>
        <ClientSideSuspense fallback={<></>}>
          <ThreadNotificationToastContent
            notification={notification}
            onClose={onClose}
          />
        </ClientSideSuspense>
      </ErrorBoundary>
    </Provider>
  )
}

export default ThreadNotificationToast
