import { createLiveblocksContext } from '@liveblocks/react'

export type CommentsProvider = ReturnType<
  typeof createLiveblocksContext
>['LiveblocksProvider']

export const DefaultCommentsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => <>{children}</>

/**
 * Provides the AdminCommentsProvider which allows a user to view message sent to WingWork Support and reply as WingWork support if they're within the support inbox
 *
 * To populate these values, get them from the useComments hook.  This hook should only be called once per provider type (admin vs. normal comments)
 */
const AdminCommentContext = React.createContext<{
  AdminCommentsProvider: CommentsProvider
  hasLoaded: boolean
}>({
  AdminCommentsProvider: DefaultCommentsProvider,
  hasLoaded: false,
})

export default AdminCommentContext
