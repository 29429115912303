import { ClientSideSuspense, RoomProvider } from '@liveblocks/react'
import { ErrorBoundary } from '@sentry/react'
import { useContext } from 'react'
import CommentContext from 'src/components/Comments/CommentContext'
import CommentsDrawerContent from 'src/components/Comments/CommentsDrawerContent'
import CommentsDrawerScaffold from 'src/components/Comments/CommentsDrawerScaffold'
import LoadingCommentsDrawer from 'src/components/Comments/LoadingCommentsDrawer'

const CommentsDrawer = () => {
  const { roomId } = useContext(CommentContext)

  return (
    <ErrorBoundary fallback={<CommentsDrawerScaffold />}>
      <ClientSideSuspense fallback={<LoadingCommentsDrawer />}>
        <RoomProvider id={roomId}>
          <CommentsDrawerContent />
        </RoomProvider>
      </ClientSideSuspense>
    </ErrorBoundary>
  )
}

export default CommentsDrawer
