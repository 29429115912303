import { ClientSideSuspense } from '@liveblocks/react/suspense'
import { CircularProgress } from '@mui/material'
import { ErrorBoundary } from '@sentry/react'
import { useContext } from 'react'
import CommentsFailedIcon from 'src/components/Comments/CommentsFailedIcon'
import SupportNotificationPopover from 'src/components/Notifications/SupportNotificationPopover'
import AdminCommentContext from 'src/components/Comments/AdminCommentContext'

const SupportIcon = () => {
  const { AdminCommentsProvider, hasLoaded } = useContext(AdminCommentContext)

  if (!hasLoaded) return <CircularProgress className="p-1" />

  return (
    <AdminCommentsProvider>
      <ErrorBoundary fallback={<CommentsFailedIcon />}>
        <ClientSideSuspense fallback={<CircularProgress className="p-1" />}>
          <SupportNotificationPopover />
        </ClientSideSuspense>
      </ErrorBoundary>
    </AdminCommentsProvider>
  )
}

export default SupportIcon
