import { createLiveblocksContext } from '@liveblocks/react'

export type CommentsProvider = ReturnType<
  typeof createLiveblocksContext
>['LiveblocksProvider']

export const DefaultCommentsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => <>{children}</>

/**
 * Provides the CommentsProvider which allows a user to view comments on pages, reply to them, etc. as the logged in user
 *
 * To populate these values, get them from the useComments hook.  This hook should only be called once per provider type (admin vs. normal comments)
 */
const CommentContext = React.createContext<{
  CommentsProvider: CommentsProvider
  roomId: string
  hasLoaded: boolean
}>({
  CommentsProvider: DefaultCommentsProvider,
  roomId: '',
  hasLoaded: false,
})

export default CommentContext
